@import "~ngx-toastr/toastr";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$personnel-primary: mat-palette($mat-indigo, 800);
$personnel-accent: mat-palette($mat-orange, 700);
// $personnel-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$personnel-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$personnel-theme: mat-light-theme(
  (
    color: (
      primary: $personnel-primary,
      accent: $personnel-accent,
      warn: $personnel-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($personnel-theme);
$back-color: linear-gradient(45deg, #8fc0c9, #c22532, #253dc2, #1dc742);
// $back-color: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)),
// url("./../../../assets/img/entertainment.png") 50%;

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.container {
  width: 75%;
  margin: auto;
  display: block;
  position: relative; // Se requiere para tener elementos en el interior de tipo absolute que respeten el relative
  padding: 20px !important;
  border-radius: 5px !important;
  background-color: white;
}
.container-welcome {
  width: 75%;
  margin: auto;
  display: block;
  position: relative; // Se requiere para tener elementos en el interior de tipo absolute que respeten el relative
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 15px !important;
  //   padding-bottom: 5px !important;
  border-radius: 5px !important;
  //   //   background-color: white;
}

.full-width {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.mtop {
  margin-top: 1em;
}

.mbot {
  margin-bottom: 1em;
}

.mtop-module {
  margin-top: 2rem;
}

.mat-spinner.mat-progress-spinner {
  display: inline-block;
  margin-right: 0.5em;
}
.mat-dialog-container {
  border-radius: 15px !important;
}
.welcome-text-panel {
  font-size: 18px;
  font-weight: bold;
}
